import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { apiendpoint } from '../endpoint';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const UpdateModal = ({ 
    showModal, 
    setShowModal, 
    selectedCode, 
    newMaxUses, 
    setNewMaxUses, 
    handleUpdateUses, 
    updating,
    updateSuccess 
  }) => {
    return (
      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton style={{ background: '#1e1e1e', borderBottom: '1px solid #333' }}>
          <Modal.Title style={{ color: '#fff' }}>Update Maximum Uses</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#1e1e1e', color: '#fff' }}>
          <p>
            Referral Code: <span className="px-2 py-1 bg-dark rounded">{selectedCode}</span>
          </p>
          
          {updateSuccess ? (
            <div className="text-success d-flex align-items-center">
              <i className="bi bi-check-circle me-2"></i>
              Successfully updated!
            </div>
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="maxUses">New Maximum Uses:</Form.Label>
                <Form.Control
                  type="number"
                  id="maxUses"
                  value={newMaxUses}
                  onChange={(e) => setNewMaxUses(e.target.value)}
                  min="1"
                  placeholder="Enter new maximum uses"
                  style={{
                    background: '#2a2a2a',
                    border: '1px solid #444',
                    color: '#fff'
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        
        {!updateSuccess && (
          <Modal.Footer style={{ background: '#1e1e1e', borderTop: '1px solid #333' }}>
            <Button 
              variant="secondary" 
              onClick={() => setShowModal(false)}
              style={{ background: '#333', borderColor: '#444' }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleUpdateUses}
              disabled={!newMaxUses || updating}
              style={{ 
                background: 'linear-gradient(135deg, #ff0000, #ff4444)',
                borderColor: '#ff0000'
              }}
            >
              {updating ? 'Updating...' : 'Update'}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  const ReferralsDashboard = ({ isLoggedIn, user, token }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [editingId, setEditingId] = useState(null);
    const [newMaxUses, setNewMaxUses] = useState('');
    const [updating, setUpdating] = useState(false);
    const navigation = useHistory();
    const limit = 10;
  
    useEffect(() => {
      if (!isLoggedIn) {
        navigation.replace("/");
      }
    }, [isLoggedIn, user, navigation]);
  
    const fetchData = async (page) => {
      try {
        setLoading(true);
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
  
        const response = await axios.post(apiendpoint.path + '/v1/users/referrals', {
          page: page,
          limit: limit
        }, { headers });
  
        if (response.data.success) {
          setUsers(response.data.data.users);
          setTotalPages(response.data.data.pagination.total_pages);
          setTotalUsers(response.data.data.pagination.total_users);
        }
      } catch (error) {
        console.error('Error fetching referrals:', error);
        navigation.replace("/");
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchData(currentPage);
    }, [currentPage, token]);
  
    const handleRecharge = (code) => {
      setEditingId(code);
      setNewMaxUses('');
    };
  
    const handleUpdateUses = async (code) => {
      if (!code || !newMaxUses || updating) return;
      
      try {
        setUpdating(true);
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
  
        const response = await axios.post(apiendpoint.path + '/v1/users/updatereferrals', {
          referralCodeId: code,
          newMaxUses: parseInt(newMaxUses)
        }, { headers });
  
        if (response.data.success) {
          fetchData(currentPage);
          setEditingId(null);
          setNewMaxUses('');
        }
      } catch (error) {
        console.error('Error updating referral code:', error);
      } finally {
        setUpdating(false);
      }
    };
  
    if (loading) {
      return (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      );
    }
  
    return (
      <div className="container">
        <div className="table-wrapper">
          <div className="header">
            <img src="https://storage.googleapis.com/assets_verboace/thunder.png" alt="Logo" className="logo" />
          </div>
          <div className="total-users">Total Users with Referral Codes: {totalUsers}</div>
          <table className="table">
            <thead>
              <tr>
                <th>Recharge</th>
                <th>User</th>
                <th>Referral Code</th>
                <th>Usage</th>
                <th>Referred Users</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    {editingId === user.referral_code ? (
                      <div className="edit-controls">
                        <input
                          type="number"
                          value={newMaxUses}
                          style={{color:"black"}}
                          onChange={(e) => setNewMaxUses(e.target.value)}
                          min="1"
                          color='black'
                          placeholder="New max uses"
                          className="max-uses-input"
                        />
                        <button 
                          className="update-button"
                          style={{marginLeft:2}}
                          onClick={() => handleUpdateUses(user.referral_code)}
                          disabled={!newMaxUses || updating}
                        >
                          {updating ? 'Updating...' : 'Update'}
                        </button>
                        <button 
                          className="cancel-button"
                          onClick={() => setEditingId(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button 
                        className="recharge-button"
                        onClick={() => handleRecharge(user.referral_code)}
                      >
                        Recharge
                      </button>
                    )}
                  </td>
                  {/* Rest of the table row remains the same */}
                  <td>
                    <div className="user-info">
                      <img src={user.display_pic} alt={user.name} className="display-pic" />
                      <span>{user.name}</span>
                    </div>
                  </td>
                  <td className="referral-code">{user.referral_code}</td>
                  <td>
                    <div className="usage-wrapper">
                      <div className="progress-bar">
                        <div 
                          className="progress" 
                          style={{ width: `${(user.current_uses / user.max_uses) * 100}%` }}
                        ></div>
                      </div>
                      <span className="usage-text">{user.current_uses}/{user.max_uses}</span>
                    </div>
                  </td>
                  <td>
                    <div className="referred-users-wrapper">
                      {user.referred_users.length > 0 ? (
                        <div className="referred-users-horizontal">
                          {user.referred_users.map((referral) => (
                            <div className="referred-user" key={referral.referred_user_id}>
                              <img 
                                src={referral.display_pic}
                                alt={referral.user_name} 
                                className="display-pic small" 
                              />
                              <div className="referred-user-info">
                                <span>{referral.user_name}</span>
                                <span className="referred-date">
                                  {new Date(referral.used_at).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="no-referrals">No referrals yet</div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {totalPages > 1 && (
          <div className="pagination-wrapper">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            ))}
          </div>
        )}
      <style jsx>{`
        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          background-color: #121212;
          color: #fff;
          min-height: 100vh;
        }
        
        .recharge-button {
          background: linear-gradient(135deg, #ff0000, #ff4444);
          color: white;
          border: none;
          padding: 8px 16px;
          border-radius: 20px;
          cursor: pointer;
          transition: all 0.2s ease;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.5px;
        }

        .recharge-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(255, 0, 0, 0.3);
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal {
          background-color: #1e1e1e;
          border-radius: 12px;
          padding: 24px;
          width: 90%;
          max-width: 400px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
        }

        .modal-content {
          color: #fff;
        }

        .modal-content h2 {
          margin: 0 0 16px 0;
          color: #ff0000;
          font-size: 24px;
        }

        .code-highlight {
          background-color: #333;
          padding: 4px 8px;
          border-radius: 4px;
          font-family: monospace;
          color: #ff0000;
        }

        .input-group {
          margin: 24px 0;
        }

        .input-group label {
          display: block;
          margin-bottom: 8px;
          color: #888;
        }

        .input-group input {
          width: 100%;
          padding: 12px;
          background-color: #2a2a2a;
          border: 1px solid #444;
          border-radius: 6px;
          color: #000;
          font-size: 16px;
        }

        .input-group input:focus {
          outline: none;
          border-color: #ff0000;
          box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
        }

        .modal-actions {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          margin-top: 24px;
        }

        .cancel-button {
          background-color: #333;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .cancel-button:hover {
          background-color: #444;
        }

        .update-button {
          background: linear-gradient(135deg, #ff0000, #ff4444);
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .update-button:hover:not(:disabled) {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(255, 0, 0, 0.3);
        }

        .update-button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .success-message {
          text-align: center;
          color: #00ff00;
          font-size: 18px;
          margin: 20px 0;
          padding: 16px;
          background-color: rgba(0, 255, 0, 0.1);
          border-radius: 6px;
        }

        /* Existing styles remain the same */
        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0px;
        }
        
        .logo {
          height: 30px;
          width: 30px;
          object-fit: contain;
        }
        
        .total-users {
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          color: #ff0000;
          margin: 20px 0;
          text-shadow: 0 0 10px rgba(255, 0, 0, 0.3);
        }
        
        .table-wrapper {
          background: #1e1e1e;
          border-radius: 10px;
          padding: 20px;
          margin: 20px 0;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          overflow-x: auto;
          width: 1200px;
        }
        
        .table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
        }
        
        .table th {
          background-color: #2c2c2c;
          color: #ff0000;
          padding: 15px;
          text-align: left;
          font-weight: bold;
          border-bottom: 2px solid #333;
        }
        
        .table td {
          padding: 15px;
          border-bottom: 1px solid #333;
        }
        
        .table tbody tr:hover {
          background-color: #2a2a2a;
        }
        
        .user-info {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        
        .display-pic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        
        .display-pic.small {
          width: 30px;
          height: 30px;
        }
        
        .referral-code {
          background-color: #333;
          padding: 6px 12px;
          border-radius: 20px;
          font-family: monospace;
          color: #ff0000;
          display: inline-block;
          margin: 10px;
        }
        
        .usage-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        
        .progress-bar {
          flex-grow: 1;
          height: 8px;
          background-color: #333;
          border-radius: 4px;
          overflow: hidden;
        }
        
        .progress {
          height: 100%;
          background: linear-gradient(90deg, #ff0000, #ff4444);
          transition: width 0.3s ease;
        }
        
        .usage-text {
          min-width: 45px;
          color: #888;
          font-size: 14px;
        }
        
        .referred-users-wrapper {
          max-width: 100%;
          overflow-x: hidden;
        }

        .referred-users-horizontal {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          padding: 4px;
        }
        
        .referred-user {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: #2a2a2a;
          padding: 8px;
          border-radius: 8px;
          transition: transform 0.2s ease;
          flex: 0 0 auto;
        }
        
        .referred-user:hover {
          transform: translateY(-2px);
        }
        
        .referred-user-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        
        .referred-date {
          font-size: 12px;
          color: #888;
        }
        
        .no-referrals {
          color: #666;
          font-style: italic;
          text-align: center;
          padding: 10px;
          border: 1px dashed #333;
          border-radius: 4px;
        }
        
        .pagination-wrapper {
          display: flex;
          justify-content: center;
          gap: 8px;
          margin-top: 20px;
          padding: 20px 0;
        }
        
        .pagination-button {
          background-color: #333;
          color: white;
          border: none;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;
          min-width: 40px;
        }
        
        .pagination-button:hover {
          background-color: #444;
          transform: translateY(-2px);
        }
        
        .pagination-button.active {
          background-color: #ff0000;
          box-shadow: 0 0 10px rgba(255, 0, 0, 0.3);
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .loader-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-color: #121212;
        }

        .loader {
          width: 50px;
          height: 50px;
          border: 4px solid #333;
          border-top: 4px solid #ff0000;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
        
        @media (max-width: 768px) {
          .container {
            padding: 10px;
          }
          
          .table-wrapper {
            width: 100%;
            padding: 10px;
          }
          
          .table th,
          .table td {
            padding: 10px;
          }
          
          .total-users {
            font-size: 20px;
          }
          
          .display-pic {
            width: 30px;
            height: 30px;
          }
          
          .pagination-button {
            padding: 6px 12px;
            min-width: 35px;
          }
          
          .referred-user-info {
            font-size: 14px;
          }

          .modal {
            width: 95%;
            padding: 16px;
          }

          .modal-content h2 {
            font-size: 20px;
          }

          .input-group input {
            padding: 10px;
            color:black;
          }

          .recharge-button {
            padding: 6px 12px;
            font-size: 11px;
          }
              .edit-controls {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        .max-uses-input {
          width: 80px;
          padding: 6px;
          background-color: #2a2a2a;
          border: 1px solid #444;
          border-radius: 4px;
          color: #000;
          font-size: 14px;
        }

        .max-uses-input:focus {
          outline: none;
          border-color: #ff0000;
          box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
        }

        .update-button {
          background: linear-gradient(135deg, #ff0000, #ff4444);
          color: white;
          border: none;
          padding: 6px 12px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 12px;
          transition: all 0.2s ease;
                  margin-left:10px;
        }

        .update-button:hover:not(:disabled) {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(255, 0, 0, 0.3);
        }

        .update-button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .cancel-button {
          background-color: #333;
          color: #fff;
          border: none;
          padding: 6px 12px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 12px;
          transition: all 0.2s ease;
        }

        .cancel-button:hover {
          background-color: #444;
        }

        @media (max-width: 768px) {
          .edit-controls {
            flex-direction: column;
            gap: 4px;
          }

          .max-uses-input {
            width: 100%;
          }

          .update-button,
          .cancel-button {
            width: 100%;
            margin-left:10px;
          }
    
        }
      `}</style>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  token: state.auth.token
});

export default connect(mapStateToProps)(ReferralsDashboard);