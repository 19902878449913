import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Comp/footer';
import Header1 from './Comp/Header1';
import Wordsstories from './Comp/Wordsstories';
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import pic from './images/pic.png'
import pc from './images/pc.png'
import logo from './images/philomath_logo.png'
import chrome from './images/chrom.png'
import Main from './Comp/main';
import Mediaplayer from './Comp/Videoplayer';
import Feature1 from './Comp/feature1';
import Feature2 from './Comp/feature2';
import Feature3 from './Comp/feature3';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Cards from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'material-icons/iconfont/material-icons.css';
import splash4 from './images/ai.gif'
import correct from './images/correct.gif'
import splash from './images/androidintegration.gif'
import { v4 as uuidv4 } from "uuid";
import splash2 from './images/quiz.gif'
import { Status } from "status-modal/dist";
import Carousel from "./carousel.jsx";
import { Dots } from 'loading-animations-react';
import splash3 from './images/notifications.gif'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { Wave } from 'react-animated-text';
import { TypeAnimation } from 'react-type-animation';
import { LightSpeed } from 'react-reveal';
import { Slide } from "react-awesome-reveal";
import Carroussel from './carousel.jsx';
import TextAnimation from './textAnimation';
import HomeNew from './Home';
import ArticleSection from './articles';
import ContactForm from './contactform';
import Test from './secret';
import PrivacyPolicy from './Comp/privacy-policy';
import RefundPolicy from './Comp/refund-policy';
import TermsAndConditions from './Comp/terms-conditions';
import TeamComponent from './Comp/ourTeam.jsx';
import LoadingComponent from './Comp/loadingComponent.jsx';
import RCTest from './rctest.jsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin,useGoogleLogin } from '@react-oauth/google';
import { Button, Card } from '@mui/material';
import axios from 'axios'
import LoginModal from './Comp/loginModal.jsx';
import { QuizProvider } from './quizContext.js';
import Dashboard from './Comp/dashboard.jsx';
import CombinedChartComponent from './Comp/analytics.jsx';
import Testimonials from './Comp/Testimonial.jsx';
import Testimonial from './Comp/Testimonial.jsx';
import UserTable from './usersAdmin.js';
import NotifyAdmin from './notifyAdmin.js';
import LightsaberLoader from './Comp/loading.jsx';
import RecognizedBy from './Comp/recognisedBy.jsx';
import VideoPopupModal from './Comp/videoPopup.jsx';
import TestimonialCard from './Comp/reviewcard.jsx';
import Referrals from './Comp/referrals.jsx';
// import jwt_decode from 'jwt-decode';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
  authDomain: "philomath-web.firebaseapp.com",
  projectId: "philomath-web",
  storageBucket: "philomath-web.appspot.com",
  messagingSenderId: "750741503708",
  appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
  measurementId: "G-YP22P8VPHR"
};
// Initialize Firebase


function Root(){
  const app = initializeApp(firebaseConfig);
  const containerRef = useRef(null);
// Export firestore database
// It will be imported into your react app whenever it is needed
 const db = getFirestore(app);

 const home = useRef(null);
 const product = useRef(null);
 const story = useRef(null);
 const contactus = useRef(null);


 const scrollToRef = (ref) => {
   if (ref.current) {
     ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }
 };
 const analytics = getAnalytics(app);
  const [loading,setLoading] = useState(true)
  const [modalState,setModalState] = useState(false)
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showAnimations,setShowAnimations]=useState(false)
  const [showText,setShowText]= useState(false)
  const [success,setSuccess] = useState(false)
  const [EmailWrong,setEmailWrong] = useState(false)
  const [first,setFirst]=useState(false)
  const [second,setSecond]=useState(false)
  const [third,setThird]=useState(false)
  const [fourth,setFourth]=useState(false)
  const [fifth,setFifth]=useState(false)
  const [sixth,setSixth]=useState(false)
  const [seventh,setSeventh]=useState(false)
  const [eight,seteight]=useState(false)
  const [ninth,setninth]=useState(false)
  const [tenth,settenth]=useState(false)
  const [eleventh,seteleventh]=useState(false)
  const [firstTime,setFirstTime] = useState(true)
  const [showAnimation,setShowAnimation] = useState(false)
  const [animationCompleted,setAnimationCompleted] = useState(true)
  const [user,setUser] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(true);

  const handleLoginModalClose = () => setShowLoginModal(false);
  const handleLoginModalShow = () => setShowLoginModal(true);

  const modalstatechange = () => {
    if (window.innerWidth > 760) {
      // Open modal for larger screens (like desktop)
      setModalState((e) => !e);
    } else {
      // Mobile deep link to open YouTube app
      window.location.href = 'vnd.youtube://ZdB4owV-QoA';
    }
  };

  const testimonialsData = [
    {
      quote: "“These treats are real tail-waggers”",
      author: "My pug Sir Biscuit is obsessed with the Salmon Snackers! He does a little dance when he hears the Barkin' Baxter's truck coming. - Lily R.",
      styleClass: 'a'
    },
    {
      quote: '"A bona-fido game-changer"',
      author: 'Baxters are at the top of the food chain! Maximus is a picky eater but he loves these treats. - Albert Z.',
      styleClass: 'b'
    },
    {
      quote: '"Treat time has never been so dog-gone amazing"',
      author: 'Our little Mabel gives them two paws up and a whole lot of slobbery kisses. - Jennifer T.',
      styleClass: 'c'
    }
  ];

  useEffect(() => {
    // Load main background image
    const bgImage = new Image();
    bgImage.onload = () => {
      setFourth(true);
    };
    bgImage.src = 'https://storage.googleapis.com/assets_verboace/bg-hd.png';

    const bgImage1 = new Image();
    bgImage1.onload = () => {
      setFirst(true);
    };
    bgImage1.src = 'https://storage.googleapis.com/assets_verboace/typepilot.png';

    const bgImage2 = new Image();
    bgImage2.onload = () => {
      setSecond(true);
    };
    bgImage2.src = 'https://storage.googleapis.com/assets_verboace/second.png';


    const bgImage3 = new Image();
    bgImage3.onload = () => {
      setThird(true);
    };
    bgImage3.src = 'https://storage.googleapis.com/assets_verboace/third.png';


    const bgImage4 = new Image();
    bgImage4.onload = () => {
      seteight(true);
    };
    bgImage4.src = 'https://storage.googleapis.com/assets_verboace/percentile_improvement.png';


    const bgImage5 = new Image();
    bgImage5.onload = () => {
      setninth(true);
    };
    bgImage5.src = 'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iphone%208%20plus%20-%202024-06-28%20at%2022.12.25.png';


    const bgImage6 = new Image();
    bgImage6.onload = () => {
      settenth(true);
    };
    bgImage6.src = 'https://storage.googleapis.com/assets_verboace/Simulator%20Screenshot%20-%20iPhone%2015%20Pro%20Max%20-%202024-06-27%20at%2001.18.05.png';


    const bgImage7 = new Image();
    bgImage7.onload = () => {
      seteleventh(true);
    };
    bgImage7.src = 'https://storage.googleapis.com/assets_verboace/VERBOACE.COM.png';
    
    // Simulate loading delay (you can remove this in actual implementation)

  }, []);

  
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

 


  const [y, setY] = useState(window.scrollY);

const handleNavigation = useCallback(
  e => {
  
    const window = e.currentTarget;
    if (y > window.scrollY ) {
      if(seventh && !showAnimation){
        setAnimationCompleted(true)
      }
      console.log(showAnimation,animationCompleted)
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    } else if (y < window.scrollY) {
      
      if(width<600){
        console.log(y)
        if(y>1000){
          if(firstTime){
          const focused = window.document.querySelector('.interactive')
          focused.scrollIntoView({ behavior: 'auto' })
          containerRef.current.focus();
          setShowAnimation(true)
          setAnimationCompleted(false)
          setFirstTime(false)
        }
        }
      }
      else{
        if(firstTime){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      containerRef.current.focus();
      setShowAnimation(true)
      setFirstTime(false)
      }
    }
    if(firstTime){
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    }
  }
    setY(window.scrollY);
  }, [y]
);



  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const [email,setEmail] = useState('')
  const addEmail = async (e) => {
        e.preventDefault();  
        if(validateEmail(email.trim())){
        try {
            const docRef = await addDoc(collection(db, "emails"), {
              email: email.trim(),    
            });
            console.log("Document written with ID: ", docRef.id);
            setEmail('')
            setSuccess(true)
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        }else{
          setEmailWrong(true)
        }
    }
 

const [load1,setload1] = useState(true)
const [load2,setload2] = useState(true)
const [load3,setload3] = useState(true)
const [load4,setload4] = useState(true)
const [load5,setload5] = useState(true)

const ref1 = useRef()
const ref2 = useRef()
const ref3 = useRef()


const onLoad1 = ()=>{
  setload1(true)
}
const onLoad2 = ()=>{
  setload2(true)
}
const onLoad3 = ()=>{
  setload3(true)
}
useEffect(() => {
  if(load1 && load2 && load3){
    setShowAnimations(true)
    setTimeout(()=>{
      setShowText(true)
    },500)
  }
}, [load1,load2,load3]);

useEffect(()=>{
console.log(width)
},[width])



  const [ profile, setProfile ] = useState([]);


  const LoginBackend = async(email,token)=>{
    const logininfo = await axios.post(
      'https://api.verboace.com/v1/auth/loginweb',
      {
        email: 'your_email@example.com',
        token: 'your_token_here'
      }
    );
    console.log(logininfo)
  }

  const login = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${access_token}` } },
      );
  
      LoginBackend(userInfo.data.email,access_token);
    },
  });

return(
<div style={{backgroundColor:"black"}}>
<GoogleOAuthProvider clientId="346911359027-8egt0g77ajs8h9s3j0067tb9nuo7e1b9.apps.googleusercontent.com">
<QuizProvider>
{(true) ?
<Router>
      <div style={{backgroundColor:"black"}}>
        <Switch>
        {/* <Route path="/analytics">
            <CombinedChartComponent />
          </Route> */}
        <Route path="/testing">
            {/* Render the Test component when the URL is /secret */}
            <RCTest />
          </Route>
          <Route path="/admin">
            {/* Render the Test component when the URL is /secret */}
            <UserTable />
          </Route>
          <Route path="/referrals">
            {/* Render the Test component when the URL is /secret */}
            <Referrals />
          </Route>
          <Route path="/notify">
            {/* Render the Test component when the URL is /secret */}
            <NotifyAdmin />
          </Route>
          <Route path="/home">
            {/* Render the Test component when the URL is /secret */}
            <Dashboard />
          </Route>
          {/* Route for the secret path */}
          <Route path="/super-secret">
            {/* Render the Test component when the URL is /secret */}
            <Test />
          </Route>
            <Route path="/terms-conditions">
            {/* Render the Test component when the URL is /secret */}
            <TermsAndConditions />
          </Route>
          <Route path="/privacy-policy">
            {/* Render the Test component when the URL is /secret */}
            <PrivacyPolicy />
          </Route>
          <Route  path="/login-admin">
          <LoginModal show={showLoginModal} handleClose={handleLoginModalClose} />
          </Route>

          <Route path="/refund-policy">
            {/* Render the Test component when the URL is /secret */}
            <RefundPolicy />
          </Route>
          <Route path="/">
          <Helmet>
        <title>TypePilot - Simple,Fun and Addictive tool for android..</title>
        <meta name="description" content="TypePilot - Simple,Fun and Addictive tool for android." />
        <meta name="keywords" content="TypePilot - Simple,Fun and Addictive tool for android. " />
      </Helmet>
{true ? <div ref={home} style={{backgroundColor:"white",overflowAnchor:"none",display:'flex',flexDirection:"column",background:"black"}}>


<VideoPopupModal state={modalState} statemodal={modalstatechange} />

    {/* <a href="/">Home</a> | <a href="/register">Add Bill</a> */}

<HomeNew scrollToRef={scrollToRef} home={home} modalState={modalstatechange} product={product} story={story} contactus={contactus} />


    {/* <Footer></Footer> */}
<div className='main' id='main' style={{display:"flex",height:"auto",justifyContent:"space-evenly",alignItems:"center",width:100+"%",background:"white",padding:0}}>
  
 <div style={{backgroundColor:"black",height:100+"%", display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",padding:0,width:100+"%"}}>
 <div style={{marginTop:50}}>

</div>
 <div ref={product} style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,

  color: 'rgb(36, 34, 62)',
  fontSize: '36px',
  lineHeight: '71px',
  marginTop:width>600?50:0+"%",
  background:"black",
  color:"#fff",
  textAlign:"center",
  padding:16
}}>
  Product You Can't Miss
</div>
{/* <Mediaplayer></Mediaplayer> */}

<div style={{display:"flex",flexDirection:width>760?"row":"column",justifyContent:"space-evenly",alignItems:"center",marginBottom:100,marginTop:20}}>

      <Card elevation={10} style={{backgroundColor:"black"}}>
        <img src={"https://storage.googleapis.com/assets_verboace/Pastel%20Download%20Now%20Phone%20Mockup%20Instagram%20Post%20(1).png"} alt="Placeholder" style={{borderRadius:20,height:"500px",marginRight:width>760?30:0,marginBottom:width>760?0:30}}  />
        </Card>

        <Card elevation={10} style={{backgroundColor:"black"}}>
        <img src={"https://storage.googleapis.com/assets_verboace/06.png"} alt="Placeholder" style={{borderRadius:20,height:"500px",marginRight:width>760?30:0,marginBottom:width>760?0:30}}  />
       </Card>

       <Card elevation={10} style={{backgroundColor:"black"}}>
        <img src={"https://storage.googleapis.com/assets_verboace/03-1.png"} alt="Placeholder" style={{borderRadius:20,height:"500px",marginRight:width>760?30:0,marginBottom:width>760?0:30}}  />
       </Card>

       <Card elevation={10} style={{backgroundColor:"black"}}>
        <img src={"https://storage.googleapis.com/assets_verboace/Pastel%20Download%20Now%20Phone%20Mockup%20Instagram%20Post%20(2).png"} alt="Placeholder" style={{borderRadius:20,height:"500px"}}  />
      </Card>


      

    </div>

    <h2 style={{fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,

  color: 'white',
  fontSize: '36px'
  }}>Initial Reactions of Early Users</h2>

    <div className="testimonial-container">
      <TestimonialCard
        name="Timothy Goebel"
        quote="Wow, TypePilot is bridging the gap between Apple and Android this is amazing."
        pic="https://storage.googleapis.com/assets_verboace/1716133916642.jpeg"
        source="Top AI Voice Linkedin"
        link={"https://www.linkedin.com/feed/update/urn:li:ugcPost:7237492962157334530?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7237492962157334530%2C7237496296360566784%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287237496296360566784%2Curn%3Ali%3AugcPost%3A7237492962157334530%29"}
      />
      <TestimonialCard
        name="Pelican Steve"
        quote="Opening articles in study mode is such a neat feature for students."
        pic="https://storage.googleapis.com/assets_verboace/1722506211045.jpeg"
        source="Top Personal Development Voice Linkedin"
        link={"https://www.linkedin.com/feed/update/urn:li:ugcPost:7237492962157334530?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7237492962157334530%2C7237496553039368192%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287237496553039368192%2Curn%3Ali%3AugcPost%3A7237492962157334530%29"}
      />
      <TestimonialCard
        name="Jyoti Swaroop"
        quote="Summarizing websites with just a single tap anywhere will save so much time!"
        pic="https://storage.googleapis.com/assets_verboace/1704454815397.jpeg"
        source="Head of Marketing & Growth, Instantpay"
        link={"https://www.linkedin.com/feed/update/urn:li:ugcPost:7237492962157334530?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7237492962157334530%2C7237494205672042496%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287237494205672042496%2Curn%3Ali%3AugcPost%3A7237492962157334530%29"}
      />
      <style jsx>{`
        .testimonial-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
          padding: 20px;
        }
        .testimonial {
          width: 100%;
          max-width: 500px;
          background: #fff;
          padding: 4em 3em;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: relative;
          box-shadow: 0 2px 2px hsl(0deg 0% 0%/.075), 0 3px 3px hsl(0deg 0% 0%/.075), 0 5px 5px hsl(0deg 0% 0%/.075), 0 9px 9px hsl(0deg 0% 0%/.075), 0 17px 17px hsl(0deg 0% 0%/.075);
        }
        .quote {
          position: absolute;
          font-size: 3em;
          width: 40px;
          height: 40px;
          background: red;
          color: #fff;
          text-align: center;
          line-height: 1.25;
        }
        .quote.open {
          top: 0;
          left: 0;
        }
        .quote.close {
          bottom: 0;
          right: 0;
        }
        p {
          width: 60%;
          display: inline-block;
          font-weight: bold;
          font-size: 1.25em;
        }
        .source {
          width: 40%;
          height: 100%;
          position: relative;		
        }
        .source span {			
          display: block;
          font-weight: bold;
          font-size: 1.15em;
          position: relative;
          margin-left: 1rem;
          text-align: right;			
        }
        .source span:before {
          content: "\\2014";
          display: inline;
          margin-right: 5px;
        }
        .image {
          transform: rotate(-5deg);
          position: absolute;
          top: 0.5em;
          right: 1.5em;
        }
        .image img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border: 10px solid #fff;
        }
        .clip {
          border: 2px solid #222;
          border-right: none;
          height: 75px;
          width: 20px;
          position: absolute;
          right: 30%;
          top: -15%;
          border-radius: 25px;
        }
        .clip:before {
          content: "";
          position: absolute;
          top: -1px;
          right: 0;
          height: 10px;
          width: 16px;
          border: 2px solid #222;
          border-bottom: none;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          z-index: 99;					
        }
        .clip:after {
          content: "";
          position: absolute;
          bottom: -1px;
          right: 0;
          height: 40px;
          width: 16px;
          border: 2px solid #222;
          border-top: none;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          z-index: 99;
        }
        @media (max-width: 768px) {
          .testimonial {
            padding: 2em 1.5em;
          }
          p {
            width: 100%;
            font-size: 1em;
          }
          .source {
            width: 100%;
            text-align: right;
          }
          .image img {
            width: 80px;
            height: 80px;
          }
        }
      `}</style>
    </div>

<div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
<RecognizedBy />
</div> 
<div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
<ContactForm />
</div> 
<div style={{width:100+"vw"}}>
<Footer></Footer>
</div>
</div>
 </div>
</div>
:
<div style={{background:"#000",display:"flex",width:100+"%",height:100+"vh",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
<div class="loader"></div>
<LightsaberLoader></LightsaberLoader>
<div style={{color:"white",fontSize:25,fontWeight:"bold",margin:0}}>TypePilot Loading please wait...</div>
</div>}
</Route>
</Switch>
</div>
</Router>:
<div style={{background:"#000",display:"flex",width:100+"%",height:100+"vh",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
<div class="loader"></div>
<LightsaberLoader></LightsaberLoader>
<div style={{color:"white",fontSize:25,fontWeight:"bold",margin:0}}>TypePilot Loading please wait...</div>
</div>}
</QuizProvider>
</GoogleOAuthProvider>

</div>
);

}
export default Root;

